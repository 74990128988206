<template>
  <div class="introduction">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="title">个人简介</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <van-field
        class="introducInput"
        :border="false"
        v-model="introducText"
        rows="6"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入个人简介"
        show-word-limit
      />
      <div class="confirm" @click="modifyInfo">确认</div>
    </div>
  </div>
</template>

<script>
import { Field, Toast } from 'vant';
import { modifyPersonInfo } from '@/api/user';
export default {
  name: 'Introduction',
  components: {
    [Field.name]: Field,
  },
  data() {
    return {
      introducText: '',
    };
  },
  created() {
    this.introducText = this.$store.getters.userInfo.summary;
  },
  methods: {
    async modifyInfo() {
      let req = { summary: this.introducText };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(modifyPersonInfo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          Toast('修改成功');
          this.$router.go(-1);
        } else {
          Toast(res.tip || '修改失败，请稍后再试');
        }
      } catch (error) {
        Toast('操作失败');
        this.$store.commit('app/SET_LOADING', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  box-sizing: border-box;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    padding: 16px 14px;
    border-radius: 4px;
    .introducInput {
      border-radius: 4px;
      /deep/ .van-field__word-limit {
        color: #ffffff50;
        font-size: 16px;
      }

      /deep/ .van-field__control {
        color: rgb(153, 153, 153);
        font-size: 16px;
      }

      ::placeholder {
        color: rgb(153, 153, 153);
        font-size: 16px;
      }
    }
    .confirm {
      width: 100%;
      height: 38px;
      background-color: rgb(255, 103, 143);
      text-align: center;
      color: #fff;
      line-height: 38px;
      border-radius: 4px;
      margin-top: 16px;
    }
  }
}
</style>
